<template>
  <div class="signup-container">
    <div class="signup-container__central">
      <img
        v-if="globalSettings && globalSettings.useLogo"
        :src="logoUrl()"
        class="logo"
      />
      <h2 v-if="globalSettings && globalSettings.newsletter">
        {{ globalSettings.newsletter.tagline }}
      </h2>

      <template v-if="globalSettings && globalSettings.newsletter && globalSettings.newsletter.mcFormAction">
        <!-- Begin Mailchimp Signup Form -->
        <div id="mc_embed_signup">
        <form
          :action="`${globalSettings.newsletter.mcFormAction}`"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <div id="mc_embed_signup_scroll">
            <div class="mc-field-group">
              <input
                placeholder="Enter your email"
                type="email"
                value=""
                name="EMAIL"
                class="required email"
                id="mce-EMAIL"
              >
              <div class="clear">
                <input
                  type="submit"
                  value="Sign Up"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  class="button"
                >
              </div>
            </div>
            <div id="mce-responses" class="clear">
              <div class="response" id="mce-error-response" style="display:none"></div>
              <div class="response" id="mce-success-response" style="display:none"></div>
            </div>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b0cd0a21782b24abaf91eb0f5_5c2775fcd0" tabindex="-1" value=""></div>
          </div>
        </form>
        </div>
        <!--End mc_embed_signup-->
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'globalSettings'
    ])
  }
}
</script>

<style lang="scss" scoped>
.signup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -53%);
  width: 100%;
  max-width: 450px;
  padding: 0 $space-m;
  margin: 0 auto;

  &__central {
    width: 100%;
  }

  .logo {
    width: 100%;
  }

  h2 {
    font-size: $font-size-ml;
    margin: $space-s 0 $space-ms;
  }

  #mc_embed_signup{
    .mc-field-group {
      display: flex;
    }
    input[type=email] {
      outline: none;
      text-align: center;
      // text-transform: uppercase;
      border: solid 2px white;
      padding: $space-s;
      margin-right: $space-xs;
      width: 100%;
      color: white;
    }
    input[type=submit] {
      font-family: $font-title;
      font-weight: bold;
      background-color: white;
      border: solid 2px white;
      color: $blk-black;
      text-transform: uppercase;
      padding: $space-s $space-ms;
    }
  }

  @include media($bp-tablet) {
    h2 {
      max-width: 300px;
      font-size: $font-size-m;
    }
    #mc_embed_signup{
      .mc-field-group {
        display: block;
      }
      input[type=submit] {
        display: block;
        margin: $space-ms auto;
      }
    }
  }
}
</style>
